<template>
  <div class="vg_wrapper">
    <el-card>
      <publicTips></publicTips>
      <div class="vd_search_group">
        <el-form :inline="true" size="min" label-width="120px" :model="searchForm" ref="searchForm" @keyup.enter.native="getSuppsNow()">
          <el-row>
            <el-col :md="8">
              <el-form-item label="供应商编号:">
                <el-input size="small" v-model.trim="searchForm.supp_no" clearable placeholder="请填写供应商编号"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="供应商名称:">
                <el-input size="small" v-model.trim="searchForm.supp_name" clearable placeholder="请填写供应商名称"></el-input>
              </el-form-item>
            </el-col>
            <el-col :md="8">
              <el-form-item label="供应商类型:">
                <el-select v-model="searchForm.supp_type" placeholder="请选择供应商类型" size="small" clearable>
                  <el-option v-for="item in suppType" :key="item.id" :label="item.label" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :md="12">
              <el-form-item label="主营类目:">
                <el-select filterable v-model="searchForm.supp_maincate" placeholder="请选择主营类目" size="small" clearable>
                  <el-option v-for="item in mainBusinessList" :key="item.id" :label="item.param1" :value="item.param1"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-button size="small" type="primary" icon="el-icon-search" @click="getSuppsNow()" class="vg_ml_16">查询</el-button>
                <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"
                  ><i class="el-icon-refresh-right"></i> 刷新</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="vd_button_group vg_mtb_16">
        <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
        <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button>
      </div>
      <el-row>
        <el-col :md="24">
          <el-table
            class="vg_cursor"
            ref="multiTable"
            :data="tableData"
            @selection-change="handleSelectionChange"
            @select="selectRows"
            @select-all="selectRows"
            @row-dblclick="dbClickJp"
            border
            v-loading="loadFlag"
          >
            <el-table-column type="selection" width="48" align="center" />
            <el-table-column label="供应商编号" prop="supp_no" />
            <el-table-column label="供应商名称" prop="supp_name" />
            <el-table-column label="主营类目" prop="supp_maincate">
              <template slot-scope="scope">
                <span v-if="scope.row.supp_maincate">
                  {{ scope.row.supp_maincate }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="类型" prop="supp_type" :formatter="formType" />
            <el-table-column label="联系人" prop="liaison_name">
              <template slot-scope="scope">
                <span v-if="scope.row.liaison_name">
                  {{ scope.row.liaison_name }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="联系方式" prop="liaison_tel">
              <template slot-scope="scope">
                <span v-if="scope.row.liaison_tel">
                  {{ scope.row.liaison_tel }}
                </span>
                <span v-else class="vg_9f9a9a">暂无</span>
              </template>
            </el-table-column>
            <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina" />
            <el-table-column label="创建时间" prop="create_time" :formatter="formatDate" />
            <el-table-column label="审批状态" :show-overflow-tooltip="true" align="center">
              <template slot-scope="scope">
                <el-tag :type="helper.getStatusName(scope.row.status).type" size="mini">{{
                  helper.getStatusName(scope.row.status).name
                }}</el-tag>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <el-row>
        <el-col :md="24">
          <pubPagination :totalPage="totalPage" @changePageSearch="changePageSearch" ref="pubPagination"></pubPagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { get, post } from '@api/request';
import { suppAPI } from '@api/modules/supp';
import { optnAPI } from '@api/modules/optn';
import pubPagination from '@/components/common/pubPagination';
import publicTips from '@/views/component/publicTips';

export default {
  name: 'SuppList',
  components: {
    pubPagination,
    publicTips
  },
  data() {
    return {
      tableData: [],
      searchForm: {
        supp_no: null,
        supp_name: null,
        supp_country: null,
        supp_maincate: null
      },
      totalPage: 0,
      btn: {},
      loadFlag: true,
      multiSelection: [],
      currentPage: 1,
      suppType: [
        { id: 1, label: '加工厂' },
        { id: 2, label: '面料厂' },
        { id: 3, label: '配套厂' },
        { id: 4, label: '辅料厂' }
      ],
      value1: 0,
      value2: 0,
      value3: 0,
      mainBusinessList: []
    };
  },
  created() {
    this.initData();
  },
  watch: {
    $route(to, from) {
      if (from.path === '/supp_add' || from.path === '/supp_edit') {
        this.initData();
      }
    }
  },
  methods: {
    initData() {
      this.getSuppsList();
      this.getCompType();
    },
    // 获取供应商信息
    getSuppsList() {
      get(suppAPI.getSupps, {
        supp_no: this.searchForm.supp_no,
        supp_name: this.searchForm.supp_name,
        supp_type: this.searchForm.supp_type,
        supp_maincate: this.searchForm.supp_maincate,
        page_no: this.currentPage
      })
        .then(res => {
          if (res.data.code === 0) {
            this.loading = false;
            this.tableData = res.data.data.list;
            this.totalPage = res.data.data.total;
            this.btn = res.data.data.btn;
            setTimeout(() => {
              this.loadFlag = false;
            }, 500);
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取成分
    getCompType() {
      get(optnAPI.getOptnByPermId, { perm_id: 10012 })
        .then(res => {
          if (res.data.code === 0) {
            this.mainBusinessList = res.data.data.form.optn_cntt_list;
          } else {
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({ message: mg, type: tp });
          }
        })
        .catch(res => {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({ message: mg, type: tp });
        });
    },
    // 刷新
    buttonRefresh() {
      this.searchForm = {
        supp_no: null,
        supp_name: null,
        supp_type: null,
        supp_maincate: null
      };
      this.loadFlag = true;
      this.currentPage = 1;
      this.initData();
      this.$refs.pubPagination.currentPage = 1;
    },
    // 查询方法
    getSuppsNow() {
      this.loadFlag = true;
      this.$refs.pubPagination.currentPage = 1;
      this.currentPage = 1;
      this.getSuppsList();
    },
    // 多选
    handleSelectionChange(val) {
      this.multiSelection = val;
    },
    // 判断是否可以删除
    selectRows(selection) {
      this.btn.delete = this.helper.isDelete(selection);
    },
    // 双击row跳转到详情编辑
    dbClickJp(row) {
      const permId = this.$route.query.perm_id;
      this.jump('/supp_edit', { perm_id: permId, form_id: row.supp_id });
    },
    // //新增
    addRow() {
      const permId = this.$route.query.perm_id;
      this.jump(`/supp_add?perm_id=${permId}`);
    },
    // 分页查询
    changePageSearch(val) {
      this.currentPage = val;
      this.getSuppsList();
    },
    // 时间转换
    formatDate(row) {
      return this.helper.toStringDate(row.create_time);
    },
    // 类型转换
    formType(val) {
      if (val.supp_type === 1) {
        return '加工厂';
      } else if (val.supp_type === 2) {
        return '面料厂';
      } else if (val.supp_type === 3) {
        return '配套厂';
      } else if (val.supp_type === 4) {
        return '辅料厂';
      }
    },
    // 未选中提示
    doDelete() {
      if (this.multiSelection.length === 0) {
        this.$message({ type: 'warning', message: '请至少选择一条数据！' });
      } else {
        let temp = false;
        for (let i = 0; i < this.multiSelection.length; i++) {
          if (this.multiSelection[i].status === 2) {
            temp = true;
            break;
          }
        }
        if (temp) {
          this.$message({ type: 'error', message: '此单据已生效或者正在审批中,无法删除！' });
          this.$refs.multiTable.clearSelection();
        } else {
          this.mBox();
        }
      }
    },
    //供应商信息list删除提示
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          const ids = [];
          this.multiSelection.map(item => {
            ids.push(item.supp_id);
          });
          post(suppAPI.deleteSuppByIds, { supp_id_list: ids })
            .then(res => {
              let mg = res.data.msg;
              if (res.data.code === 0) {
                let tp = 'success';
                this.$message({ message: mg, type: tp });
                if (ids.length === this.tableData.length) {
                  if (this.currentPage > 1) {
                    this.currentPage = this.currentPage - 1;
                    this.$refs.pubPagination.currentPage = this.currentPage;
                  }
                }
                this.initData();
              } else {
                let tp = 'error';
                this.$message({ message: mg, type: tp });
                this.initData();
              }
            })
            .catch(res => {
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({ message: mg, type: tp });
              this.initData();
            });
        })
        .catch(() => {
          let mg = '取消删除！';
          let tp = 'info';
          this.$message({ message: mg, type: tp });
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_elimg {
  width: 60px;
  height: 60px;
}
</style>
